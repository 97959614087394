<template>
  <van-popup v-model:show="show" round :close-on-click-overlay="false">
    <div class="rate-dialog">
      <p>{{title}}</p>
      <van-cell
       v-for="(item, index) in rateList"
       :key="index"
       :border="false"
       :title="item.name"
       :value="item.value"
      />
      <van-icon class="dialog-close-icon" name="cross" color="#fff" size="22" @click="close" />
    </div>
  </van-popup>
</template>

<script>
  
export default {
  name: 'RateDialog',
  props: {
    show: Boolean,
    title: String,
    rateList: Object,
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit('hide')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.rate-dialog {
  position: relative;
  width: 300px;
  padding-bottom: 10px;
  font-size: 16px;
  p {
    line-height: 20px;
    padding: 16px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background: var(--van-primary-color);
  }
  .van-cell__title, 
  .van-cell__value {
    font-size: 14px;
    font-weight: 500;
    color: var(--van-primary-color);
  }
  .van-cell__value { flex: 2 }
  .dialog-close-icon {
    position: absolute;
    top: 16px;
    right: 12px;
  }
}
</style>
