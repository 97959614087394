<template>
  <van-popup v-model:show="show" round :close-on-click-overlay="false">
    <div class="share-dialog">
      <p class="title">{{title}}</p>
      <p class="invitation-code">{{shareInfo.invitationCode}}</p>
      <!-- <p>{{$t('field.invitationCodeRate')}}</p>
      <van-cell
       v-for="(item, index) in shareInfo.rateList"
       :key="index"
       :border="false"
       :title="item.paymentMethodName"
       :value="item.shareRatePercent"
      /> -->
      <div v-if="jsBrige" class="share-link" @click="openUrl">
        <van-icon name="share-o" size="18" />
        <span class="share-text">{{$t('button.share')}}</span>
      </div>
      <a v-else class="share-link" :href="shareUrl" target="_blank">
        <van-icon name="share-o" size="18" />
        <span class="share-text">{{$t('button.share')}}</span>
      </a>
      <van-icon class="dialog-close-icon" name="cross" color="#fff" size="22" @click="close" />
    </div>
  </van-popup>
</template>

<script>
import { getShareInfo, getShareUrl } from "@/api";
  
export default {
  name: 'ShareDialog',
  props: {
    show: Boolean,
    title: String,
  },
  data() {
    return {
      shareInfo: {},
      shareUrl: '',
      jsBrige: window.jsBridge,
    };
  },
  computed: {
  },
  mounted() {
    this.fetchShareInfo();
    this.fetchShareUrl();
  },
  methods: {
    fetchShareInfo() {
      getShareInfo({}).then(res => {
        this.shareInfo = res.data;
      });
    },
    fetchShareUrl() {
      getShareUrl({}).then(res => {
        this.shareUrl = res.data;
      });
    },
    openUrl() {
      if (window.jsBridge) {
        window.jsBridge.invokeNative('openUrl', this.shareUrl, '');
      }
    },
    close() {
      this.$emit('hide')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.share-dialog {
  position: relative;
  width: 300px;
  padding-bottom: 20px;
  font-size: 16px;
  text-align: center;
  .title {
    line-height: 20px;
    padding: 16px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background: var(--van-primary-color);
  }
  .invitation-code {
    font-size: 25px;
    margin: 30px 0;
    color: var(--van-primary-color);
  }
  .share-link {
    display: inline-block;
    margin: 0 auto;
    padding: 0 24px;
    height: 36px;
    line-height: 34px;
    text-align: center;
    font-size: 14px;
    color: #fff;
    border-radius: 18px;
    background: var(--van-primary-color);
    .van-icon {
      margin-right: 6px;
      vertical-align: middle;
    }
    .share-text {
      vertical-align: middle;
    }
  }
  .dialog-close-icon {
    position: absolute;
    top: 16px;
    right: 12px;
  }
}
</style>
