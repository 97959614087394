<template>
  <div class="workbench" :style="`background: ${$AppData.config.appPageInfoList.filter(item => item.code === 'WorkbenchFragment')[0]?.backgroundColor || '#f6f8fb'} url(${$AppData.config.backgroundImgUrl}) 0 0/100% auto no-repeat;`">
    <p class="page-title">{{$t('title.workbench')}}</p>
    <div class="user-info-card">
      <div class="user-info-item">
        <p class="user-info-label">{{$t('field.available')}}({{$AppData.config.appCoin}})</p>
        <p class="user-info-data">{{accountInfo.balanceStr}}</p>
      </div>
      <div class="user-info-item">
        <van-button
          size="small"
          type="primary"
          round
          @click="goToJournal"
        >{{$t('title.accountDetail').toUpperCase()}}</van-button>
        <div v-if="$AppData.config.themeType" class="btn-bar">
          <van-button
            size="small"
            type="primary"
            style="margin-right: 10px;"
            round
            @click="goToRecharge"
          ><van-icon class-prefix="my-icon" name="recharge" size="26" /></van-button>
          <van-button
            size="small"
            type="primary"
            round
            @click="goToWithdraw"
          ><van-icon class-prefix="my-icon" name="withdraw" size="26" /></van-button>
        </div>
      </div>
      <template v-if="$AppData.config.themeType">
        <div class="user-info-item left-card light-primary" style="border-radius: 9px 0 0 0;">
          <p class="icon-circle"><van-icon class-prefix="my-icon" name="order" size="20" /></p>
          <p class="user-info-data">{{accountInfo.freezeStr}}</p>
          <p class="user-info-label">{{$t('field.frozen')}}({{$AppData.config.appCoin}})</p>
        </div>
        <div class="user-info-item right-card primary" style="border-radius: 0 9px 0 0;">
          <p class="icon-circle"><van-icon class-prefix="my-icon" name="income" size="20" /></p>
          <p class="user-info-data">{{accountInfo.totalIncomeStr}}</p>
          <p class="user-info-label">{{$t('field.income')}}({{$AppData.config.appCoin}})</p>
        </div>
        <div class="user-info-item left-card primary" style="border-radius: 0 0 0 9px;" >
          <p class="icon-circle"><van-icon class-prefix="my-icon" name="star" size="20" /></p>
          <p class="user-info-data">{{accountInfo.earnestStr}}</p>
          <p class="user-info-label">{{$t('field.securityDeposit')}}</p>
        </div>
        <div class="user-info-item right-card light-primary" style="border-radius: 0 0 9px 0;">
          <p class="icon-circle"><van-icon class-prefix="my-icon" name="expiring" size="20" /></p>
          <p class="user-info-data">{{accountInfo.todayExpire}}</p>
          <p class="user-info-label">{{$t('field.expiring')}}</p>
        </div>
      </template>
      <template v-else>
        <div class="user-info-item">
          <p class="user-info-label">{{$t('field.frozen')}}({{$AppData.config.appCoin}})</p>
          <p class="user-info-data">{{accountInfo.freezeStr}}</p>
        </div>
        <div class="user-info-item">
          <p class="user-info-label">{{$t('field.income')}}({{$AppData.config.appCoin}})</p>
          <p class="user-info-data">{{accountInfo.totalIncomeStr}}</p>
        </div>
      </template>
    </div>
    <pagelist page="WorkbenchFragment" />
  </div>
  <van-action-sheet
    v-model:show="showActions"
    :actions="actions"
    :description="actionTitle"
    @select="onSelectAction"
    :close-on-click-overlay="false"
  />
</template>

<script>
import i18n from '@/assets/lang/index';
import pagelist from '@/components/PageList';

import { queryAccountInfo, queryRechargePage, queryWithdrawPage } from "@/api";


export default {
  components: {
    pagelist,
  },
  
  data() {
    return {
      accountInfo: {},
      showActions: false,
      actionTitle: '',
      actions: [],
    };
  },
  
  mounted() {
    this.fetchAccountInfo();
  },

  methods: {
    fetchAccountInfo() {
      queryAccountInfo().then(res => {
        this.accountInfo = res.data || {};
      });
    },
    goToRecharge() {
      queryRechargePage().then(res => {
        let pages = res.data.tempVOList || [];
        if (pages.length === 1) {
          this.goToPage({ key: pages[0].code, name: pages[0].name });
        } else {
          let actions = pages.map(item => ({ key: item.code, name: item.name }) );
          actions.push({ key: 'BACK', name: i18n.global.t('button.back'), color: '#ee0a24' });
          this.actionTitle = i18n.global.t('tabTitle.recharge');
          this.actions = actions;
          this.showActions = true;
        }
      });
    },
    goToWithdraw() {
      queryWithdrawPage().then(res => {
        let pages = res.data.tempVOList || [];
        if (pages.length === 1) {
          this.goToPage({ key: pages[0].code, name:  pages[0].name });
        } else {
          let actions = pages.map(item => ({ key: item.code, name: item.name }) );
          actions.push({ key: 'BACK', name: i18n.global.t('button.back'), color: '#ee0a24' });
          this.actionTitle = i18n.global.t('tabTitle.withdrawal');
          this.actions = actions;
          this.showActions = true;
        }
      });
    },
    goToPage(item) {
      let page = this.$AppData.pageMapping[item.key];
      if (page) {
        localStorage.setItem('pageName', item.name);
        this.$router.push(page);
      }
    },
    goToJournal() {
      this.$router.push('/journal');
    },
    onSelectAction(item) {
      if(item.key == 'BACK') {
        this.showActions = false;
      } else {
        let page = this.$AppData.pageMapping[item.key];
        if (page) {
          localStorage.setItem('pageName', item.name);
          this.$router.push(page);
        } else {
          this.goBack();
        }
      }
    },
  },
};
</script>

<style lang="less">
.workbench {
  min-height: 100vh;
  padding-bottom: 50px;
  box-sizing: border-box;
  .page-title {
    padding: 10px 16px;
    line-height: 60px;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    text-align: center;
  }
  .user-info-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 16px;
    padding: 12px 0;
    font-size: 13px;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    .user-info-item {
      width: 50%;
      padding: 16px 0;
      box-sizing: border-box;
      .user-info-label {
        line-height: 20px;
        margin-bottom: 10px;
      }
      .van-button {
        min-width: 100px;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
.theme1 .workbench {
  .user-info-card {
    margin: 0 20px;
    padding: 10px 15px 20px;
    .user-info-item {
      padding: 6px 0 12px;
      .user-info-label {
        color: #3d3d3d;
        font-size: 14px;
      }
      .user-info-data {
        color: #ee5f5f;
        font-size: 23px;
        font-weight: 700;
      }
      // &.left-card { border-radius: 9px 0 0 9px; }
      // &.right-card { border-radius: 0 9px 9px 0; }
      &.primary { background: @primary-color; }
      &.light-primary { background: @light-primary-color; }
      &.left-card, &.right-card {
        padding: 10px 0 8px;
        .user-info-label { font-size:12px; color: #fff; margin: 4px 0 0; }
        .user-info-data { font-size:19px; font-weight: normal; color: #fff; }
        .icon-circle {
          width: 22px;
          height: 22px;
          padding: 4px;
          margin: 0 auto 6px;
          background: #fff;
          border-radius: 50%;
          color: @primary-color;
        }
      }
      .van-button {
        width: 100%;
        min-width: 60px;
        height: 36px;
        font-size: 13px;
        font-weight: 500;
        // color: @primary-color;
        color: #fff;
        background-color: @light-primary-color;
        border-color: @light-primary-color;
      }
      .btn-bar {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
      }
    }
  }
}
</style>
