<template>
  <div :class="`page-list mode${mode}`">
    <van-row gutter="18">
      <van-col v-for="(pageItem,index) in configFunction" :span="pageItemSpan" :key="pageItem.code">
        <div v-if="mode == 0 || mode == 4" class="page-item" @click="goToPage(pageItem)" >
          <van-image class="page-icon" :src="pageItem.iconUrl" />
          <p class="page-name">{{pageItem.name}}</p>
          <p v-if="pageItem.data != null" class="page-data">{{pageItem.data}}</p>
          <van-icon class="arrow-icon" name="arrow" />
        </div>
        <div v-else-if="mode == 3" class="page-item" @click="goToPage(pageItem)" >
          <div class="page-icon" :style="pageItem.color ? `background: ${pageItem.color};` : ''" >
            <van-image  :src="pageItem.iconUrl" />
            <p v-if="pageItem.data" class="badge-data">{{pageItem.data}}</p>
          </div>
          <p class="page-name">{{pageItem.name}}</p>
        </div>
        <div v-else class="page-item" :style="pageItem.color ? `background: ${pageItem.color};` : ''" @click="goToPage(pageItem)" >
          <van-image class="page-icon" :src="pageItem.iconUrl" />
          <div class="rigit-wrap">
            <p class="page-name">{{pageItem.name}}</p>
            <p v-if="pageItem.data" class="badge-data">{{pageItem.data}}</p>
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
  <sharedialog :show="showShare" :title="dialogTitle" @hide="showShare = false" />
  <ratedialog :show="showRate" :title="dialogTitle" :rateList="rateList" @hide="showRate = false" />
  
</template>

<script>
import i18n from '@/assets/lang/index';
import moment from 'moment';
import { store } from '@/store';
import sharedialog from '@/components/ShareDialog';
import ratedialog from '@/components/RateDialog';
import { queryConfigFunction, queryAccountInfo, queryAccountMarkingRate } from "@/api";
  
export default {
  name: 'PageList',
  components: {
    sharedialog,
    ratedialog,
  },
  props: {
    page: String,
  },
  setup() {
    const themeVars = {
      // badgeSize: '20px',
      badgePadding: '3px 6px',
    };
    return {
      themeVars
    };
  },
  data() {
    return {
      store,
      configFunction: [],
      mode: 3,
      dialogTitle: '',
      showShare: false,
      showRate: false,
      rateList: [],
      accountInfo: {},
    };
    
  },
  computed: {
    pageItemSpan() {
      if(this.mode === 0 || this.mode === 4) {
        return 24;
      } else if(this.mode === 3) {
        return 6;
      } else {
        return 12;
      }
    },
  },
  mounted() {
    this.fetchConfigFunction();
  },
  methods: {
    fetchConfigFunction() {
      queryConfigFunction({ page: this.page }).then(res => {
        this.configFunction = res.data.tempVOList
          .filter(item => item.code != 'SETTINGS')
          .map(item => {
            if (item.code === 'VERSION') {
              return { ...item, data: process.env.VUE_APP_VERSION };
            }
            return item;
          });
        this.mode = res.data.model;
      });
    },
    fetchAccountInfo(callback) {
      queryAccountInfo().then(res => {
        let rateList = res.data.rateList.map(rateItem => ({
          name: rateItem.paymentMethodName,
          value: rateItem.ratePercent,
        }));
        callback && callback(rateList);
      });
    },
    fetchAccountMarkingRate(callback) {
      queryAccountMarkingRate().then(res => {
        let rateList = res.data.rateTextList.map(rateItem => ({
          name: rateItem.paymentMethodName,
          value: rateItem.text,
        }));
        callback && callback(rateList);
      });
    },
    goToPage(item) {
      switch (item.code){
        case 'VERSION':
          this.$myDialog.confirm({
            title: i18n.global.t('alert.warnning'),
            message: i18n.global.t('alert.updateVersion'),
          })
          .then(() => {// on confirm
            if (window.jsBridge) {
              window.jsBridge.invokeNative('refresh', '', '');
            } else {
              window.location.href = `/?t=${moment().unix()}`;
            }
          })
          .catch(() => {});
          break;
        case 'MULTI_LEVEL_CODE':
          this.dialogTitle = item.name;
          this.showShare = true;
          break;
        case 'USER_RATE':
          this.dialogTitle = item.name;
          this.fetchAccountInfo(rateList => {
            this.rateList = rateList;
            this.showRate = true;
          });
          break; 
        case 'SETTLE_RATE':
          this.dialogTitle = item.name;
          this.fetchAccountMarkingRate(rateList => {
            this.rateList = rateList;
            this.showRate = true;
          });
          break; 
        case "NUMBER_OF_USER_REGISTRATIONS"://注册人数
        case "INCOME"://收益
        case "REBATE"://返佣
        case "VOLUME"://成交量
        case "PURCHASE_AMOUNT"://购买量
        case "SUCCESS_RATE"://成功率
        case "NUMBER_OF_ORDER"://订单数
        case "BUY_COINS_QUICKLY_VOLUME"://急速跑量
        case "BUY_COINS_QUICKLY_INCOME"://急速收益
          localStorage.setItem('historyCode', item.code);
          localStorage.setItem('pageName', item.name);
          this.$router.push('/history');
          break;
        case 'JUMP_LINK':
          this.openUrl(item.url);
          break;
        case 'MARKET_PLACE':
          location.href = store.transactionUrl;
          // this.openUrl(store.transactionUrl);
          break;
        default:
          const page = this.$AppData.pageMapping[item.code];
          if(!page) {
            return false;
          }
          localStorage.setItem('pageName', item.name);
          this.$router.push(page);
          break;
      }
    },
    openUrl(url) {
      if (window.jsBridge) {
        window.jsBridge.invokeNative('openUrl', url, '');
      } else {
        let newWin = window.open();
        if(newWin) {
          newWin.location = url;
        } else {
          let a = document.createElement('a');
          a.setAttribute('target','_blank');
          a.setAttribute('href',  url);
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.page-list.mode0{
  margin: 12px 20px;
  padding: 4px 0;
  border-radius: 8px;
  background: #fff;
  .page-item {
    display: flex;
    align-items: center;
    line-height: 24px;
    margin: 0 15px;
    padding: 12px 0;
    font-size: 14px;
    background-color: #fff;
    border-bottom: 1px solid #efefef;
  }
  .van-col:last-child .page-item {
    border-bottom: none; 
  }
  .page-icon,
  .page-icon img{
    width: 35px;
    height: 35px;
  }
  .page-name {
    margin-left: 14px;
    font-weight: 700;
    flex: 1;
    color:#3d3d3d;
  }
  .page-data {
    width: 90px;
    color: var(--van-primary-color);
    font-size:13px;
    text-align: right;
  }
  .arrow-icon {
    display: block;
    margin-left: 6px;
    font-size: var(--van-cell-icon-size);
    color: var(--van-cell-right-icon-color);
  }
}
.page-list.mode1,
.page-list.mode2 {
  padding: 16px;
  .page-item {
    position: relative;
    display: flex;
    align-items: center;
    height: calc(100% - 48px);
    line-height: 19px;
    padding: 16px 6px 16px 12px;
    margin-bottom: 10px;
    font-size: 13px;
    background: @background-color;
    border-radius: 11px;
  }
  .page-icon,
  .page-icon img{
    width: 42px;
    height: 42px;
  }
  .rigit-wrap {
    flex: 1;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
  }
  .page-name {
    color: #3d3d3d;
    font-weight: 700;
  }
  .badge-data {
    font-weight: 500;
    font-size: 13px;
    color: red;
    text-align: center;
    padding-right: 10px;
  }
}
// .page-list.mode2{
//   padding: 16px;
//   .page-item {
//     display: flex;
//     flex-wrap: wrap;
//     // align-items: center;
//     height: calc(100% - 26px);
//     line-height: 28px;
//     padding: 8px 4px 8px 10px;
//     margin-bottom: 10px;
//     font-size: 14px;
//     background-color: #fff;
//     border-radius: 6px;
//   }
//   .page-icon,
//   .page-icon img{
//     width: 28px;
//     height: 28px;
//   }
//   .page-name {
//     padding: 4px 0;
//     line-height: 20px;
//     margin-left: 10px;
//     width: 113px;
//   }
//   .page-data {
//     width: 100%;
//     padding-left: 38px;
//     box-sizing: border-box;
//   }
//   .arrow-icon {
//     display: none;
//   }
// }
.page-list.mode3{
  padding: 20px;
  .page-icon {
    position: relative;
    display: flex;
    width: 69px;
    height: 69px;
    background: @background-color;
    border-radius: 11px;
    justify-content: center;
    align-items: center;
    .van-image,
    .van-image img {
      width: 42px;
      height: 42px;
    }
  }
  .page-name {
    margin-top: 5px;
    margin-bottom: 20px;
    text-align: center;
    color: #3d3d3d;
    font-weight: 700;
  }
  .badge-data {
    position: absolute;
    top: -6px;
    right: -6px;
    padding: 0 6px;
    font-size: 13px;
    color: #fff;
    background: #ee5f5f;
    border-radius: 10px;
  }
}
.page-list.mode4{
  margin-bottom: 20px;
  padding: 0 20px;
  background: #fff;
  .page-item {
    display: flex;
    line-height: 28px;
    padding: 12px 0;
    font-size: 14px;
    background-color: #fff;
    border-bottom: 1px solid #efefef;
  }
  .van-col:last-child .page-item {
    border-bottom: none;
  }
  .page-icon,
  .page-icon img{
    width: 28px;
    height: 28px;
  }
  .page-name {
    margin-left: 10px;
    flex: 1;
    color: #3d3d3d;
    font-weight: 700;
  }
  .page-data {
    width: 90px;
    text-align: right;
  }
  .arrow-icon {
    display: block;
    margin-left: 10px;
    line-height: 28px;
    font-size: var(--van-cell-icon-size);
    color: var(--van-cell-right-icon-color);
  }
}
</style>
